import React, { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { useUserAgent } from 'next-useragent';
import nookies, { setCookie } from 'nookies';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import { userAgentFromString } from 'next/server';
import { RootStoreProvider } from '../providers/RootStoreProvider';
import { SearchStoreProvider } from '../providers/SearchStoreProvider';
import { Layout } from '../components/Layout';
import { SearchCruises } from '../components/SearchCruises';
import { Intro } from '../components/Intro';
import styles from '../styles/mainPage.module.sass';
import { Text } from '../components/Text';
import { Collapsed } from '../components/Collapsed';
import { faqItems } from '../resources/faqItemsIndex';
import { Partners } from '../components/Partners';
import { Reviews } from '../components/Reviews';
import { Subscribe } from '../components/MainPage/Subscribe';
import { PopularDestinations } from '../components/PopularDestinations';
import { Promotions } from '../components/MainPage/Promotions';
import { PartnersBanners } from '../components/Banner/PartnersBanner/PartnersBanners';
import { PopularCruises } from '../components/PopularCruises';
import { YaAndGaMainPage } from '../lib/YaAndGaHelper';
import { yandexUserLayer } from '../lib/yandexDataLayer';
import { apiProxy } from '../lib/api';
import { useHiddenWithCookie } from '../hooks/useHiddenWithCookie';
import { logger } from '../lib/logger';
import { setPublicCacheControl } from '../lib/cacheControl';

const pageConfig = {
  title:
    'Речные круизы по России 2024 - 2025. Круизы на теплоходе по России | Круиз.онлайн',
  description:
    'Купить речной круиз по России в 2024 - 2025 году 🚢 Самый большой выбор направлений! ⭐️ 5600 круизов по России, 100 теплоходов ✅ Онлайн-бронирование',
};

const BannerTop = dynamic(() =>
  import('../components/CookieBanner').then(mod => mod.CookieBanner),
);

export default function MainPage({
  uaString,
  store: { user, vendors, searchStore, banners, typeSearch, cookies },
  main_reviews,
  popDestRiver,
  canonicalUrl,
  popularPopdirs,
}) {
  const ua = useUserAgent(uaString || window.navigator.userAgent);
  const { isMobile, isTablet } = ua;
  const [closeBanners, setCloseBanners] = useState(
    cookies?.closeBanner !== undefined || cookies?.closeBanner === 'false',
  );

  const currentDate = dayjs();
  const timeToCookie = currentDate.add(1, 'h');
  setCookie(null, 'closeBanner', 'false');
  const openBanner = () => {
    setCloseBanners(false);
    setCookie(null, 'closeBanner', 'false');
  };
  useHiddenWithCookie(openBanner);

  const { title, description } = pageConfig;

  useEffect(() => {
    // Яндекс метрика - информация о пользователи и типе страницы
    yandexUserLayer(user?.user?.id, user?.user?.group, false);
    YaAndGaMainPage('isRiverPage');
  }, []);

  return (
    <SearchStoreProvider
      hydrationData={{ user, searchStore, banners, typeSearch }}
    >
      <RootStoreProvider
        hydrationData={{ user, searchStore, banners, typeSearch }}
      >
        <NextSeo
          title={title}
          description={description}
          additionalLinkTags={[
            {
              rel: 'shortcut icon',
              href: '/favicon.ico',
            },
          ]}
          canonical={canonicalUrl}
        />
        <Layout
          isMobile={isMobile}
          isTablet={isTablet}
          showTopContent={!closeBanners}
          popularPopdirs={popularPopdirs}
          topContent={
            <div>
              <BannerTop
                isOpen={closeBanners}
                onOpen={setCloseBanners}
                timeToCookie={timeToCookie}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </div>
          }
        >
          <div className="container--block">
            <Intro title="Все круизы онлайн без наценок" />
            <SearchCruises
              typeSearch={typeSearch}
              isMobile={isMobile}
              chooseType
              startPage
            />
            <PartnersBanners
              isMobile={isMobile}
              isTablet={isTablet}
              banners={banners}
            />
            <PopularCruises
              isMobile={isMobile}
              metriks={
                'main_river_page_clicking_on_learn_more_buttons_in_popular_cruises_section'
              }
            />
            <PopularDestinations
              isMobile={isMobile}
              riverPopDest={popDestRiver}
            />
            {main_reviews && main_reviews.length && (
              <section>
                <div className={styles.reviewsHeader}>
                  <Text variant="heading-1" component="h2">
                    Отзывы
                  </Text>
                </div>
                <Reviews
                  isMobile={isMobile}
                  items={main_reviews}
                  link="/reviews"
                />
              </section>
            )}
            <Promotions isMobile={isMobile} />
            <Subscribe />
            <Partners title="Наши партнёры" vendors={vendors} />
            <section>
              {/* <Text component="h2" variant="heading-4"> */}
              {/*  Часто задаваемые вопросы */}
              {/* </Text> */}
              <Collapsed
                items={faqItems}
                shorter
                isCruis
                onClick={() => {
                  YaAndGaMainPage('open_block_with_text_content');
                }}
              />
              <br />
            </section>
          </div>
        </Layout>
      </RootStoreProvider>
    </SearchStoreProvider>
  );
}

export const getServerSideProps = async ctx => {
  const cookies = nookies.get(ctx);
  const { token = '' } = cookies;
  const canonicalUrl = `https://kruiz.online/`;
  const { isMobile, isTablet } = userAgentFromString(
    ctx.req.headers['user-agent'],
  );
  if (!token && !isMobile && !isTablet) {
    setPublicCacheControl({
      response: ctx.res,
      maxAge: 0,
      sMaxAge: 0,
      staleIfError: 7 * 24 * 60 * 60,
      staleWhileRevalidate: 7 * 24 * 60 * 60,
    });
  }
  try {
    const responses = await Promise.allSettled([
      apiProxy.get(`ApiUsers/check_token/${token ?? ''}`, {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiReviews/main_reviews?dbg=1', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiPopdir/apiPopular', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiPopdir/apiPopularSea', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiReviews/sea_reviews', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiVendors/sendVendorsPage', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('Booking2019/get_cruis_list/new_river', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiBanners/getHomepageBanners?is_sea=0', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get('ApiBanners/getHomepageBanners?is_sea=1', {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
      apiProxy.get(`ApiPopdirNew/getHeaderPopdir/0`, {
        headers: {
          'x-request-id': ctx.req.headers['x-request-id'] ?? 'qwert',
        },
      }),
    ]);

    const [
      user,
      main_reviews,
      popDestRiver,
      popDestSea,
      seaMain_reviews,
      vendors,
      cruises,
      banners,
      seaBanners,
      popularPopdirs,
    ] = responses.map(req =>
      req.status === 'fulfilled' ? req.value.data ?? null : null,
    );
    logger({
      additionalFields: { url: ctx.req.url },
      level: 'info',
      message: 'main page has been created',
    });
    return {
      props: {
        store: {
          user,
          vendors,
          banners,
          seaBanners,
          typeSearch: 'rivers',
          cookies,
          searchStore: {
            popularPageCount: cruises?.cruises?.pages_count ?? 0,
            popularCards: cruises?.cruises?.cruises_data ?? [],
          },
        },
        uaString: ctx.req.headers['user-agent'],
        main_reviews,
        seaMain_reviews,
        popDestRiver,
        popDestSea,
        canonicalUrl,
        popularPopdirs,
      },
    };
  } catch (error) {
    logger({
      additionalFields: {
        url: ctx.req.url,
      },
      error,
      level: 'error',
      message: 'main page was not created',
    });
    return {
      redirect: {
        destination: '/503',
        permanent: true,
      },
    };
  }
};
